<template lang="pug">
	.modal-tooltip
		b-tooltip(v-if="!isMobile" :target="id" no-fade :custom-class="classTooltip" triggers="hover")
			p.b3.mb-0.modal-tooltip__txt(v-if="text" v-html="text" :class="{'mb-3': textLink}")
			pw-btn-link(v-if="textLink" :text="textLink" :link="link" :href="href" :blank="true" :tooltip="true")

		b-modal(v-if="isMobile" :id="id" :content-class="{'modal-tooltip__modal': true, [classTooltip]: true }" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3.modal-tooltip__title(v-html="title")
				icon-close(@click="close()")
			p.b3.mb-4.overflow-wrap.modal-tooltip__txt(v-html="textMobile" :class="{'mb-3': textLink}")
			pw-btn-link.mb-4(v-if="textLink" :text="textLink" :link="link" :href="href" :blank="true" :tooltip="true")
			span.btn.btn-primary.modal-tooltip__btn(@click="$bvModal.hide(id)") {{ $t('resources.btnFine') }}
</template>

<script>
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'ModalTooltip',
	components: {
		PwBtnLink,
		IconClose
	},
	props: {
		id: {
			type: String,
			default: ''
		},
		link: {
			type: Object,
			default: () => ({})
		},
		href: {
			type: String,
			default: ''
		},
		textLink: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
		textMobile: {
			type: String,
			default: ''
		},
		classTooltip: {
			type: String,
			default: ''
		}
	},
}
</script>

<style lang="scss">
.modal-tooltip {
	&__modal {
		padding: 16px 20px 28px;
	}

	&__title {
		margin-right: 10px !important;
	}
}
</style>
